import React, { useState, useEffect, useContext } from "react";
import "../../allCss/usercss/applyLeave.css";
import Calendar from "react-calendar";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
// import EditApplyLeave from "./EditApplyLeave";
import leaveHome from "../../assets/leaveHome.png";
import dustbin from "../../assets/dustbin.png";
import toast, { Toaster } from "react-hot-toast";

const ApplyLeave = () => {
    const navigate = useNavigate();
    const [holiday, setHoliday] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    let loadingToast;
    // const { userId } = useContext(AuthContext);
    // console.log("userId", userId);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("role");
    if (token && role != "emp") {
        navigate("/admin/home");
    }
    const [selectedData, setSelectedData] = React.useState(new Date());
    const [data, setData] = useState({
        contact_number: "",
        startingDate: "",
        endDate: "",
        noOfDays: "",
        reason: "",
        file: null,
        type: "",
    });
    const handleFileChange = (event) => {
        const file = event.target.value; // Get the first file selected by the user
        setData((prevData) => ({
            ...prevData,
            file: file,
        }));
    };
    const [arrayData, setArrayData] = useState([]);

    const { errorHandleLogout } = useContext(AuthContext);

    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const handelCalender = (date) => {
        setSelectedData(date);
    };
    const calculateDays = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        // Adjusting the end date to include the full day
        end.setDate(end.getDate() + 1);

        let daysWithoutSundays = 0;

        while (start < end) {
            // Checking if the current day is not a Sunday (0 index in JavaScript Date object)
            if (start.getDay() !== 0) {
                daysWithoutSundays++;
            }

            // Moving to the next day
            start.setDate(start.getDate() + 1);
        }
        return daysWithoutSundays;
    };

    // const formatDate = (inputDate) => {
    //   const date = new Date(inputDate);
    //   const option = { day: "2-digit", month: "2-digit", year: "numeric" };
    //   return new Intl.DateTimeFormat("en-GB", option).format(date);
    // };

    const formatDate = (inputDate) => {
        // Check if inputDate is a valid date
        if (!inputDate || isNaN(new Date(inputDate))) {
            return "Invalid Date";
        }

        const date = new Date(inputDate);
        const option = { day: "2-digit", month: "2-digit", year: "numeric" };
        return new Intl.DateTimeFormat("en-GB", option).format(date);
    };

    const handleStartingDateChange = (e) => {
        const startingDate = e.target.value;
        const endingDate = data.ending_date;
        const noOfDays = calculateDays(startingDate, endingDate);
        setData({ ...data, startingDate, noOfDays });
    };

    // Event handler for ending date change
    const handleEndingDateChange = (e) => {
        const endingDate = e.target.value;
        const startingDate = data.startingDate;
        const noOfDays = calculateDays(startingDate, endingDate);
        setData({ ...data, ending_date: endingDate, noOfDays });
    };
    const calculateEndingDate = (startingDate, noOfDays) => {
        const start = new Date(startingDate);
        const end = new Date(start);
        end.setDate(end.getDate() + noOfDays - 1);
        return formatDate(end);
    };
    const [selectedItem, setSelectedItem] = useState({});

    const handelEdit = (item) => {
        setSelectedItem((prevItem) => ({ ...prevItem, ...item }));
        // console.log(selectedItem)
        setShowEdit(true);
    };

    const handelDelete = async (value) => {
        const conFirmDelete = window.confirm("Are you sure you want to delete");
        if (conFirmDelete) {
            try {
                loadingToast = toast.loading("Deleting leave...", {
                    position: "top-right",
                    style: {
                        marginTop: "62px",
                    },
                });
                await axios
                    .delete(
                        `${BACKEND_URL}/users/leave/delete_leave?leaveId=${arrayData[value]._id}`,
                        { headers: headers }
                    )
                    .then((res) => {
                        toast.success(res.data.message, {
                            position: "top-right",
                            style: {
                                marginTop: "62px",
                            },
                        });

                        setRefresh(refresh + 1);
                    })
                    .catch((err) => {
                        // console.log(err);
                        toast.error(`${err.response.data.message}`, {
                            position: "top-right",
                            style: {
                                marginTop: "62px",
                            },
                        });
                    });
            } catch {
                alert("Something went wrong, Try logging in again");

                errorHandleLogout();
            } finally {
                if (loadingToast) {
                    toast.dismiss(loadingToast, {
                        position: "top-right",
                        style: {
                            marginTop: "62px",
                        },
                    });
                }
            }
        }
    };

    useEffect(() => {
        try {
            const fetchData = async () => {
                await axios
                    .get(
                        `${BACKEND_URL}/users/leave/view_particular_leaves?status=all&userId=${userId}`,
                        { headers: headers }
                    )
                    .then((res) => {
                        // console.log("applyLeave all data", res.data.data);
                        setArrayData(res.data.data);
                    })
                    .catch((err) => {
                        // console.log("Error", err);
                        toast.error(`${err.response.data.message}`, {
                            position: "top-right",
                            style: {
                                marginTop: "62px",
                            },
                        });
                    });
            };
            fetchData();
        } catch {
            alert("Something went wrong, Try logging in again");

            errorHandleLogout();
        }
    }, [refresh]);

    // useEffect(() => {
    // console.log("showing array data", arrayData);
    // }, [arrayData]);
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    // console.log(year);
    useEffect(() => {
        try {
            axios
                .get(
                    `${BACKEND_URL}/holiday/get_holidays?startDate=${year}-01-01&endDate=${year}-12-31`,
                    { headers: headers }
                )
                .then((res) => {
                    // console.log("holiday leave list :", res.data);
                    setHoliday(res.data.data);
                    // toast.success(res.data.message, {
                    //     position: "top-right",
                    //     style: {
                    //         marginTop: "62px",
                    //     },
                    // });
                })
                .catch((err) => {
                    // toast.error(`${err.response.data.message}`, {
                    //   position: 'top-right',
                    //   style: {
                    //     marginTop: '62px',
                    //   }
                    // })
                });
        } catch (err) {
            // console.log("errorshowing", err);
            alert("Something went wrong, Try logging in again");

            errorHandleLogout();
        }
    }, []);
    useEffect(() => {
        // console.log("holidays:", holiday);
    }, [holiday]);

    const handelSubmit = async (e) => {
        e.preventDefault();
        // console.log("data", data);
        const formData = new FormData();

        formData.append("startDate", data.startingDate);
        formData.append("endDate", data.ending_date);
        // formData.append("noOfDays", data.noOfDays);
        formData.append("reason", data.reason);
        formData.append("supportiveDocument ", data.file);
        formData.append("type", data.type);
        try {
            loadingToast = toast.loading("Applying leave ...", {
                position: "top-right",
                style: {
                    marginTop: "62px",
                },
            });
            await axios
                .post(`${BACKEND_URL}/users/leave/apply_leave?userId=${userId}`, formData, {
                    headers: headers,
                })
                .then((res) => {
                    // console.log("for leave:", res);
                    // alert(res.data.message);
                    toast.success(res.data.message, {
                        position: "top-right",
                        style: {
                            marginTop: "62px",
                        },
                    });
                    setRefresh(refresh + 1);
                })
                .catch((err) => {
                    // console.log("leave err", err);
                    toast.error(`${err.response.data.message}`, {
                        position: "top-right",
                        style: {
                            marginTop: "62px",
                        },
                    });
                });
        } catch (err) {
            // console.log("err", err);
            alert("Something went wrong, Try logging in again");

            errorHandleLogout();
        } finally {
            // Close the loading state
            if (loadingToast) {
                toast.dismiss(loadingToast);
            }
        }
    };

    const [leaveBalance, setLeaveBalance] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get(
                    `${BACKEND_URL}/users/leave/view_leave_balance?userId=${userId}&month=${month}&year=${year}`,
                    { headers: headers }
                )
                .then((res) => {
                    // console.log("leave_balance", res.data);
                    setLeaveBalance(res.data.leaveBalance);
                    // toast.success("Leave balance loaded !", {
                    //     position: "top-right",
                    //     style: {
                    //         marginTop: "62px",
                    //     },
                    // });
                })
                .catch((err) => {
                    // toast.error(`${err.response.data.message}`, {
                    //     position: "top-right",
                    //     style: {
                    //         marginTop: "62px",
                    //     },
                    // });
                });
        };
        fetchData();
    }, []);
    return (
        <div className="apply_leave">
            {/* <div className="header">
        <p>Apply Leave</p>
        <NavLink to="/home">
          <img src={leaveHome} />
        </NavLink>
      </div> */}
            <div className="content">
                <div className="left_leave">
                    <div className="avaliable_leave">
                        <p>Avaliable Leave Balance :</p>
                        {leaveBalance && (
                            <div className="allLeaves">
                                <label htmlFor="sl"> SL :</label>
                                <input
                                    type="text"
                                    name="sl"
                                    value={leaveBalance.sl}
                                    style={{ cursor: "none" }}
                                />
                                <label htmlFor="el"> EL :</label>
                                <input
                                    type="text"
                                    name="el"
                                    value={leaveBalance.el}
                                    style={{ cursor: "none" }}
                                />
                                <label htmlFor="cl"> CL :</label>
                                <input
                                    type="text"
                                    name="cl"
                                    value={leaveBalance.cl}
                                    style={{ cursor: "none" }}
                                />
                                <label htmlFor="fl"> FL :</label>
                                <input
                                    type="text"
                                    name="fl"
                                    value={leaveBalance.fl}
                                    style={{ cursor: "none" }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="leave_calender">
                        <Calendar
                            className="calender"
                            onChange={handelCalender}
                            value={selectedData}
                        />
                    </div>
                    <div className="leave_holiday">
                        <p>HoliDays :-</p>
                        {holiday.map((item, index) => (
                            <>
                                <p key={index}>
                                    {new Date(item.dte).toISOString().split("T")[0]} : {item.name}{" "}
                                </p>
                            </>
                        ))}
                    </div>
                </div>
                <div className="right_leave">
                    <form onSubmit={handelSubmit}>
                        <div className="firstdiv">
                            <label htmlFor="contact_number"> Contact Number :</label>
                            <input
                                type="Number"
                                name="contact_number"
                                onChange={(e) =>
                                    setData({ ...data, contact_number: e.target.value })
                                }
                            />
                            <p style={{ fontSize: "13px", fontFamily: "cursive" }}>
                                The contack details displayed will be applicable for all leave
                                applications
                            </p>
                        </div>
                        <div className="main-seconddiv">
                            <div className="seconddiv">
                                <label htmlFor="startingDate"> Starting Date :</label>
                                <input
                                    type="date"
                                    name="startingDate"
                                    onChange={handleStartingDateChange}
                                />
                                <label htmlFor="ending_date"> Ending Date :</label>
                                <input
                                    type="date"
                                    name="ending_date"
                                    onChange={handleEndingDateChange}
                                />
                                <label htmlFor="leave_type"> Leave Type :</label>
                                <select
                                    value={data.type}
                                    name="leave_type"
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const noOfDays =
                                            selectedValue === "Half Day" ? 0.5 : data.noOfDays;
                                        setData({
                                            ...data,
                                            type: selectedValue,
                                            noOfDays: noOfDays,
                                        });
                                    }}
                                >
                                    <option value="">select</option>
                                    <option value="Adoption Leave">Adoption Leave</option>
                                    <option value="Advance Earned Leave">
                                        Advance Earned Leave
                                    </option>
                                    <option value="Casual Leave">Casual Leave</option>
                                    <option value="Half Day">Half Day</option>
                                    <option value="Earned Leave">Earned Leave</option>
                                    <option value="Flexi holiday">Flexi holiday</option>
                                    <option value="LWP- Unable to work">LWP- Unable to work</option>
                                    <option value="Leave without pay">Leave without pay</option>
                                    <option value="Sick leave">Sick leave</option>
                                </select>
                                <label htmlFor="noOfDays"> No.of Days :</label>
                                <input type="text" name="noOfDays" value={data.noOfDays} />
                                <label htmlFor="reason"> Detaild Reason :</label>
                                <input
                                    type="text"
                                    name="reason"
                                    onChange={(e) => setData({ ...data, reason: e.target.value })}
                                />
                                <label htmlFor="upload_document"> Upload Document :</label>
                                <input
                                    type="file"
                                    accept="image/*,.pdf"
                                    name="upload_document"
                                    onChange={handleFileChange}
                                    // onChange={(e)=>console.log(e.target.files[0])}
                                />
                            </div>
                            <div className="table">
                                {arrayData && arrayData.length > 0 ? (
                                    <table className="table_name1">
                                        <thead>
                                            <th>Starting Date</th>
                                            <th>Ending Date</th>
                                            <th>Leave Type</th>
                                            <th>No.of days</th>
                                            <th>Status</th>
                                            {/* <th>edit</th> */}
                                            <th>Delete</th>
                                        </thead>
                                        <tbody>
                                            {arrayData.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{formatDate(item.startDate)}</td>
                                                    <td>
                                                        {formatDate(item.endDate)}
                                                        {/* {calculateEndingDate(item.startDate, item.noOfDays)} */}
                                                    </td>
                                                    <td>{item.type}</td>
                                                    <td>{item.noOfDays}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <img
                                                            onClick={() => handelDelete(index)}
                                                            width="18"
                                                            height="18"
                                                            style={{ cursor: "pointer" }}
                                                            src={dustbin}
                                                            alt="filled-trash"
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>
                                        Nothing left to show !!! . You have deleted all the leaves.
                                        Please add new leave.
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="apply_button">
                            <button type="submit">submit</button>
                        </div>
                    </form>
                    {/* {showEdit && (
            <EditApplyLeave
              setShowEdit={setShowEdit}
              selectedItem={selectedItem}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )} */}
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default ApplyLeave;
