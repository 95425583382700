import React, { Fragment } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

export const RegisterUser = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem("role")

  if (role !== "admin") {
    navigate("/home")
  }
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const initialValues = {
    Email: "",
    ConfirmEmail: '',
    empId: "",
    designation: ""

  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const handleSubmit = async (values, { resetForm }) => {
    const postData = {
      email: values.Email,
      empId: values.empId,
      designation: values.designation
    }
    if (values.Email === values.ConfirmEmail) {

      let loadingToast; // Declare the variable outside the try block

      try {
        // Display loading state
        loadingToast = toast.loading("Registration in progress...");

        // Make the API request
        await axios.post(`${BACKEND_URL}/users/register`, postData, { headers: headers })
          .then(res => {
            if (res.data.success === true) {
              // Request was successful
              // const data = response.data;

              toast.success("Registration successful!", {
                // position: toast.POSITION.BOTTOM_RIGHT,
              });
              // resetForm();
            }
          })
          .catch((err) => {
            // console.log("error", err.response.data.message)
            toast.error("Error: " + err.response.data.message,{
              // position: toast.POSITION.BOTTOM_CENTER,
            });
          })
      } catch (err) {
        // console.error("Error:", err);
        toast.error("Something went wrong during registration", {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });

      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    }

    else toast.error("Confirm Email id is not same", {
      // position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  return (
    <Fragment>
    <div className="update_user">
   
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className="form">
            <h2>Register Employee Form</h2>

            <div className="form_innerDiv">
              <label htmlFor="Email">
                Enter Email Address
                <Field
                  type="email"
                  required
                  id="Email"
                  placeholder="Enter your Email"
                  name="Email"
                />
              </label>
              <ErrorMessage
                name="Email"
                className="error"
                component="div"
              />
            </div>
            <div className="form_innerDiv">
              <label htmlFor="ConfirmEmail">
                Confirm Email Address
                <Field
                  required
                  type="email"
                  id="ConfirmEmail"
                  placeholder="Enter your Email"
                  name="ConfirmEmail"
                />
              </label>
              <ErrorMessage
                name="ConfirmEmail"
                className="error"
                component="div"
              />
            </div>
            <div className="form_innerDiv">
              <label htmlFor="empId">
                Enter Employee ID
                <Field
                  required
                  type="text"
                  id="empId"
                  placeholder="Enter your EmpID"
                  name="empId"
                />
              </label>
              <ErrorMessage
                name="empId"
                className="error"
                component="div"
              />
            </div>
            <div className="form_innerDiv">
              <label htmlFor="designation">
                Enter the designation
                <Field
                  required
                  type="text"
                  id="designation"
                  placeholder="Enter your Designation"
                  name="designation"
                />
              </label>
              <ErrorMessage
                name="designation"
                className="error"
                component="div"
              />
            </div>






            <button type="submit" className="submit">
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <ToastContainer />
      </div>
    </Fragment>
  )
}
