import React, { useContext, useEffect, useState } from "react";
import "../../allCss/usercss/updateUser.css";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const UpdateUser = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  let loadingToast;
  const { errorHandleLogout } = useContext(AuthContext);
  const [previewImage, setPreviewImage] = useState(null);
  const [pathValue, setPathValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (token && role != "emp") {
    navigate("/admin/home");
  }

  const [data, setData] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    dob: '',
    dp: null,
  })
  useEffect(() => {
    if (data) {
      // console.log(data);
      setFormData({
        ...formData,
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        dob:data.dob,
        dp: data.dp
      })
    }

  }, [data])
  // useEffect(() => {
  //   // console.log("formdata",formData.dob);
  // }, [])

  useEffect(() => {
    // console.log("userId", userId);
    try {
      const getLeave = async () => {
        await axios
          .get(`${BACKEND_URL}/users/profile?userId=${userId}`, {
            headers: headers,
          })
          .then((res) => {
            // console.log("get userid for updateuser:", res.data.user.dp);
            // console.log(res.data.user);
            setData(res.data.user);


          })
          .catch((err) => {
            // console.log("err", err);
            alert("Unable to fetch data ");
          });
      };
      getLeave();
    } catch (err) {
      alert("Something went wrong, Try logging in again");

      errorHandleLogout();

    }
  }, []);

  useEffect(() => {
    handleUndefined();
  }, []);
  const handleUndefined = () => {
    if (!userId) {
      alert("Session Timeout");
      localStorage.removeItem("token");
      navigate("/");
    }
  };
  const handleChange = (event) => {

    const file = event.target.files[0];
    if (file) {
      setPathValue(file);
    }
    if (!file) {
      return;
    }



    if (file.size > 2 * 1024 * 1024) { // 2 MB limit
      setError('Image size must be less than 2 MB');
      return;
    }

    // Create preview image (optional)
    const reader = new FileReader();
    reader.onloadend = () => setPreviewImage(reader.result);
    reader.readAsDataURL(file);

    // Update form data
    // setFormData({ ...formData, dp: file });
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    // console.log(previewImage)

    const formDatas = new FormData();
    formDatas.append("email", formData.email);
    formDatas.append('password', formData.password);
    formDatas.append('phone', formData.phone);
    formDatas.append('name', formData.name);
    formDatas.append('dob', formData.dob);
    formDatas.append('image', pathValue)
    try {
      loadingToast = toast.loading("Update is going on");
      handleUndefined();
      let res = await axios
        .post(`${BACKEND_URL}/users/update?userId=${userId}`, formDatas, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' ,
          },
        })
      // console.log(res)
      toast.success(res.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      })
      navigate("/")
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      alert("Something went wrong try again later");
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }

  };
  return (
    <>
      <div className="update_user">
        <form onSubmit={handelSubmit}>
          <div style={{ display: "flex", flexDirection: "row-reverse", gap: '10px', justifyContent: "center" }}>
            {previewImage && (
              <div style={{ display: "flex", flexDirection: "column", gap: '10px', justifyContent: "center" }}>
                <label htmlFor="updatedImage">Updated DP</label>
                <img src={previewImage} alt="Preview Image" width={130} height={130} />
              </div>
            )}
            {formData.dp &&
              (
                <div style={{ display: "flex", flexDirection: "column", gap: '10px', justifyContent: "center" }}>
                  <label htmlFor="prevImage">Previous DP</label>
                  <img src={`${BACKEND_URL}${formData.dp}`} alt="Uploaded Image" width={130} height={130} />
                </div>
              )}
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {isLoading && <p>Uploading...</p>}
          <br />
          <label htmlFor="name">Name :</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <label htmlFor="email">Email :</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <label htmlFor="password">Password :</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />
          <label htmlFor="phone">Phone :</label>
          <input
            type="number"
            name="phone"
            value={formData.phone}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          />
          <label htmlFor="dob">DOB :</label>
          <input
            type="date"
            name="dob"
            value={formData.dob ? formData.dob.split("T")[0] : ""}
            onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
          />
          <label htmlFor="update_profile">update Profile :</label>
          <input
            type="file"
            name="dp"
            accept="image/jpeg,image/png,image/jpg"
            onChange={handleChange}
          />

          <button type="submit" disabled={isLoading}>Submit</button>
        </form>

      </div>
      <Toaster />
    </>
  );
};

export default UpdateUser;
