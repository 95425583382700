import React, { useState, useContext } from "react";
import cross from "../../../assets/black cross.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import AuthContext from "../../../context/AuthContext";

export const DeleteTeamMember = ({
  index,
  tmIndex,
  projectLIst,
  setShowDeleteMember,
  setRefresh,
  refresh,
}) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const { errorHandleLogout } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [reason, setReason] = useState(""); // State for reason

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .delete(
          `${BACKEND_URL}/projects/remove_member?projectId=${projectLIst[index]._id}&teamMemberId=${projectLIst[index].teamMembers[tmIndex]._id}`,
          {
            headers: headers,
            data: { reason }, // Pass the reason in the request body
          }
        )
        .then((res) => {
          toast.success(res.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
          setRefresh(refresh + 1); // Trigger refresh
          setShowDeleteMember(false); // Close modal
        })
        .catch((err) => {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        });
    } catch (err) {
      alert("Something went wrong, Try logging in again");
      errorHandleLogout();
    }
  };

  return (
    <div className="cover" style={{ color: "black" }}>
      <div className="left" style={{ backgroundColor: "white" }}>
        <img src={cross} onClick={() => setShowDeleteMember(false)} />
        <h2>Do you want to remove this team member?</h2>
        <form onSubmit={handleSubmit}>
          <h3>Team Member Details</h3>
          <ul>
            <li>
              <strong>Name:</strong>{" "}
              {projectLIst[index].teamMembers[tmIndex].userId?.name}
            </li>
            <li>
              <strong>Role:</strong>{" "}
              {projectLIst[index].teamMembers[tmIndex].role}
            </li>
            <li>
              <strong>EmpID:</strong>{" "}
              {projectLIst[index].teamMembers[tmIndex].userId?.empId}
            </li>
          </ul>
          <hr />
          <h3>Reason for Removal</h3>
          <textarea
            placeholder="Enter the reason for removing this member"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
            style={{ width: "100%", height: "100px" }}
          />
          <button style={{ cursor: "pointer" }} type="submit">
            Remove Member
          </button>
        </form>
      </div>
      <Toaster />
    </div>
  );
};
