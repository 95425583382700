import React, { useState, useEffect, useContext } from "react";
import "../allCss/holiday.css";
import edit from "../assets/edit.png";
import deletee from "../assets/delete.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import AuthContext from "../context/AuthContext";
const HolidayList = () => {
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());
  const [editingHoliday, setEditingHoliday] = useState(null);
  const [deleteHoliday, setDeleteHoliday] = useState(false);
  const [index, setIndex] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [editFormData, setEditFormData] = useState({
    name: "",
    date: "",
  });

  let loadingToast;
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const { errorHandleLogout } = useContext(AuthContext);


  useEffect(() => {
    const fetchHolidays = async (year) => {
      try {
        loadingToast = toast.loading("Fetching Holidays ...", {
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        });
        await axios
          .get(
            `${BACKEND_URL}/holiday/get_holidays?startDate=${year}-01-01&endDate=${year}-12-31`,
            { headers: headers }
          )
          .then((res) => {
            // console.log(res.data);
            setHolidays(res.data.data);
            toast.success(res.data.message, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            })
            setLoading(false);
          }).catch((err) => {
            toast.error(`${err.response.data.message}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            })
          })
      } catch {
        alert("Something went wrong, Try logging in again");

        errorHandleLogout();
      }
      finally {
        if (loadingToast) {
          toast.dismiss(loadingToast, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          });
        }
      }
    };

    fetchHolidays(year);
  }, [year, refresh]);

  // useEffect(() => {
  //   if (holidays) console.log("holidays", holidays[2]);
  // }, [holidays]);

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);
    setYear(newYear);
  };

  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      weekday: "long",
    };

    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const isCurrentMonth = (dateString) => {
    const currentDate = new Date();
    const holidayDate = new Date(dateString);
    return currentDate.getMonth() === holidayDate.getMonth();
  };

  const handleEditAction = (index) => {
    const selectedHoliday = holidays[index];
    setEditingHoliday(selectedHoliday);
    setEditFormData({
      name: selectedHoliday.name,
      date: selectedHoliday.dte,
    });
  };

  const handelDeleteModel = () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const deleteData = async () => {
        const res = await axios.delete(
          `${BACKEND_URL}/holiday/delete_holiday?holidayId=${holidays[index]._id}`,
          { headers: headers }
        );
        if (res) {
          // console.log(res);
          alert(res.data.message);
          setRefresh(refresh + 1);
          setDeleteHoliday(false);
        } else {
          alert(res.data.data.message);
        }
      };
      deleteData();
    } catch (err) {
      // console.log("asfqef", err);
    }
  };

  const handleDeleteAction = (index) => {
    setIndex(index);
    setDeleteHoliday(!deleteHoliday);
  };

  const handleCloseModal = () => {
    // console.log("closing modal");
    // setSelectedEmployeeIndex(null);
    setEditingHoliday(null);
    setEditFormData({
      name: "",
      date: "",
    });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Call the update API with the edited data
    const { name, date } = editFormData;
    const holidayId = editingHoliday._id;

    try {
      const response = await fetch(
        `${BACKEND_URL}/holiday/update_holiday?holidayId=${holidayId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            date,
          }),
        }
      );

      if (response.ok) {
        // Update the specific holiday in the state
        setHolidays((prevHolidays) => {
          return prevHolidays.map((holiday) =>
            holiday._id === holidayId
              ? {
                ...holiday,
                name: editFormData.name,
                date: editFormData.date,
              }
              : holiday
          );
        });

        setEditingHoliday(null);
        setEditFormData({
          name: "",
          date: "",
        });
      } else {
        console.error("Error updating holiday:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating holiday:", error);
    }
  };

  return (
    <div className="holiday-container">
      <div className="holiday-header">
        <div>
          Holiday List of Year:{" "}
          <input
            type="number"
            value={year}
            onChange={handleYearChange}
            className="year-input"
          />
        </div>
      </div>

      {loading ? (
        <p>Loading holidays...</p>
      ) : (
        <div className="holiday-list-table">
          <div className="holiday-list-head">
            <div className="holiday-name">Holiday Name</div>
            <div className="holiday-date">Date</div>
            <div className="holiday-edit"></div>
          </div>

          <div className="holiday-list-body">
            {holidays.map((holiday, index) => (
              <div
                key={holiday._id}
                className={`holiday-list-body-row ${isCurrentMonth(holiday.dte) ? "current-month" : ""
                  }`}
              >
                <div className="holiday-name">{holiday.name}</div>
                <div className="holiday-date">{formatDate(holiday.dte)}</div>

                {role === "admin" && (
                  <>
                    <div className="holiday-edit">
                      <img
                        src={edit}
                        alt="edit"
                        onClick={() => handleEditAction(index)}
                      />
                      <div className="popup popup2">Edit</div>
                    </div>
                    <div className="holiday-edit">
                      <img
                        src={deletee}
                        alt="delete"
                        onClick={() => handleDeleteAction(index)}
                      />
                      <div className="popup popup2">Delete</div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {editingHoliday && (
        <div className="alertBox">
          <div className="removeEmpModal">
            <h2> Edit Holiday</h2>
            <form onSubmit={handleFormSubmit}>
              <label>
                Holiday Name:
                <input
                  type="text"
                  name="name"
                  value={editFormData.name}
                  onChange={handleFormChange}
                />
              </label>
              <br />
              <label>
                Holiday Date:
                <input
                  type="text"
                  name="date"
                  value={editFormData.date}
                  onChange={handleFormChange}
                />
              </label>
              <hr />
              <div className="modalFooter">
                <button type="submit" className="btnActive">
                  Edit
                </button>

                <button onClick={() => handleCloseModal()}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {deleteHoliday && (
        <div className="alertBox">
          <div className="removeEmpModal">
            <h2> Are you sure you want to delete {holidays[index].name} </h2>
            <div className="modalFooter">
              <button
                type="submit"
                className="btnActive"
                onClick={() => handelDeleteModel()}
              >
                Delete
              </button>

              <button onClick={() => setDeleteHoliday(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default HolidayList;
