import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AuthContext = createContext({
  userId: null,
  success: null,
  setUser: () => {},
  handelSignOut: () => {},
  errorHandleLogout: () => {},
});
export const AuthContextProvider = ({ children }) => {
  // const [role, setRole] = useState("");
  // const [userId, setUserId] = useState("");
  const [success, setSucess] = useState("");
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const setUser = async (value) => {
    let loadingToast;

    // console.log(value);
    try {
      await axios
        .post(`${BACKEND_URL}/users/login`, value)
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.success === true) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("userId", res.data.userId);
            localStorage.setItem("role", res.data.role);
            if (res.data.role === "emp") {
              // console.log("employee login",res.data.message);
              // alert(res.data.message);
              toast.success(res.data.message);
              navigate("/home");
            } else if (res.data.role === "admin") {
              // console.log("Admin login");
              // alert(res.data.message);
              toast.success(res.data.message);
              navigate("/admin/home");
            }
          }
        })
        .catch((err) => {
          // console.log(err.response.data.message);
          // alert(err.response.data.message);
          toast.error(err.response.data.message)
        });
    } catch (error) {
      // alert("Wrong Credential ", error);
      toast.error("Wrong Credential ")
    }
  };

  const handelSignOut = async () => {
    const confirmLogOut = window.confirm("Are you sure you want to logout?");
    if (confirmLogOut) {
        try {
            const userId = localStorage.getItem("userId");
            // console.log("Logging out userId:", userId); 

            const res = await fetch(`${BACKEND_URL}/users/logout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({ userId }),
            });

            const responseData = await res.json(); // Parse response to check success
            // console.log("Logout response:", responseData);

            if (responseData.success) {
                toast.success("Logged Out Successfully");
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                localStorage.removeItem("role");
                navigate("/");
            } else {
                toast.error(responseData.message || "Logout Failed");
            }
        } catch (error) {
            console.error("Error during logout:", error);
            toast.error("Error during logout");
        }
    }
};



  const errorHandleLogout = () => {
    toast.success("LogOut Successfully")
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    localStorage.removeItem("adminSetUser");
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{ success, setUser, handelSignOut, errorHandleLogout }}
    >
      {children}
      <ToastContainer autoClose={1000}/>
    </AuthContext.Provider>
  );
};

export default AuthContext;
