import React, { useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import AuthContext from "../../context/AuthContext";
import axios from "axios";

export const Home = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const { errorHandleLogout } = useContext(AuthContext);
  const userId = localStorage.getItem("userId");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    // console.log("userId", userId);
    try {
      const getLeave = async () => {
        await axios
          .get(`${BACKEND_URL}/users/profile?userId=${userId}`, {
            headers: headers,
          })
          .then((res) => {
            // console.log("get userid for updateuser:", res.data.user.name);
            // console.log(res.data.user);
  localStorage.setItem('dp',res.data.user.dp)
  localStorage.setItem('name',res.data.user.name)


          })
          .catch((err) => {
            // console.log("err", err);
            alert("Unable to fetch data ");
          });
      };
      getLeave();
    } catch (err) {
      alert("Something went wrong, Try logging in again");

      errorHandleLogout();

    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Add this to enable automatic sliding
    autoplaySpeed: 3000, // Set the time (in milliseconds) between slides
  };
  
  return (
    <>
      <div className="slider">
        <Slider {...settings}>
          <div>
            <img width={600} height={600} src="https://cdn.pixabay.com/photo/2018/01/17/07/06/laptop-3087585_1280.jpg" alt="Slide 1" />
          </div>
          <div>
            <img width={600} height={600} src="https://cdn.pixabay.com/photo/2016/02/07/21/03/computer-1185626_1280.jpg" alt="Slide 2" />
          </div>
          <div>
            <img width={600} height={600} src="https://cdn.pixabay.com/photo/2016/11/22/21/26/notebook-1850613_1280.jpg" alt="Slide 3" />
          </div>
        </Slider>
      </div>
    </>
  );
};
