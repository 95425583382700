import React, { useState, useEffect } from "react";
import "../../allCss/admincss/adminhome.css";
import dangerAlert from "../../assets/dangerAlert.jpg";
import { v4 as uuidv4 } from 'uuid';
import { UpdateDetails } from "./UpdateDetails";
import { useNavigate } from "react-router-dom";
import { cancel_v1, circleUser, forward_v1, lock, mailV1, unLock, whatsapp_v4 } from "../../assets";

import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
const AdminHome = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDiv, setShowDiv] = useState([]);
  const [firstImageLoaded, setFirstImageLoaded] = useState(true);

  const [isPermanentDelete, setIsPermanentDelete] = useState(false);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmText, setConfirmText] = useState(null);
  const [enteredText, setEnteredText] = useState('');
  const [showForm, setShowForm] = useState(false);

  const [id, setId] = useState('')
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  let loadingToast;
  useEffect(() => {
    const fetchData = async () => {
      try {
        loadingToast = toast.loading("Fetching all users...", {
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        });
        const response = await fetch(`${BACKEND_URL}/users/get_all_user`, { headers: headers });
        const data = await response.json();
        // console.log(data, "fv");



        if (data.success) {
          // console.log(data.users)
          setEmployeeData(data?.users);
          toast.success('Users Data loaded Successfully', {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          });



        } else {
          setError("Failed to fetch data");
        }
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    };

    fetchData();
  }, []); // Run the effect only once on component mount
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleInputChange = (event) => {
    setEnteredText(event.target.value);
  };

  const handleCloseModal = () => {
    // console.log("closing modal");
    // setSelectedEmployeeIndex(null);
    setShowModal(false);
    setIsPermanentDelete(false);
  };

  const handleShowModal = (index) => {
    setSelectedEmployeeIndex(index);

    // Check if employeeData[selectedEmployeeIndex] is defined before accessing its properties
    const selectedEmployee = employeeData[index];
    // Close all dropdowns first
    const newShowDiv = showDiv.map(() => false);
    newShowDiv[index] = !showDiv[index];
    setShowDiv(newShowDiv);
    setShowModal(true);
    // console.log(selectedEmployee);
    if (selectedEmployee) {
      let randomCode = uuidv4().slice(0, 6);
      let aa = `Delete ${selectedEmployee.name ? selectedEmployee.name : selectedEmployee.email} ${randomCode}`;
      setConfirmText(aa);
    }

  };

  const handleRemoveAction = async () => {
    const index = selectedEmployeeIndex;

    if (index !== null) {
      const userId = employeeData[index]._id;
      const action = isPermanentDelete ? 'permanently' : 'temporarily';

      const actionURL = isPermanentDelete ? 'perm_delete_user' : 'temp_delete_user';

      try {
        // Perform your delete action here
        // You may want to send a request to the server to delete the user
        await axios.delete(`${BACKEND_URL}/users/${actionURL}?userId=${userId}`, { headers: headers })
          .then(res => {
            // alert(`${employeeData[index].name || employeeData[index].email} deleted ${action}`);
            const updatedEmployeeData = [...employeeData];
            updatedEmployeeData.splice(index, 1);
            setEmployeeData(updatedEmployeeData);
            toast.success(`${action} deletion successfully for ${employeeData[index].name || employeeData[index].email}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            });
          })
          .catch(err => {
            console.error(`Failed to ${action} delete user- ${employeeData[index].name || employeeData[index].email}`, err);
            toast.error(`Failed to ${action} delete user- ${employeeData[index].name || employeeData[index].email}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            });
          })


      } catch (error) {
        console.error(`Error while ${action} deleting user ${employeeData[index].name || employeeData[index].email}`, error);
        toast.error(`Error while ${action} deleting user ${employeeData[index].name || employeeData[index].email}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        }, error);
      }
    }

    handleCloseModal();
  };

  const handleFirstImageError = () => {
    setFirstImageLoaded(false);
  };



  const handleDropdownClick = (index) => {
    // Close all dropdowns first
    const newShowDiv = showDiv.map(() => false);
    // Toggle the state of the clicked item
    newShowDiv[index] = !showDiv[index];
    setShowDiv(newShowDiv);
  };

  const handleLockAction = async (index) => {
    const email = employeeData[index].email;
    const action = employeeData[index].isLOCKED ? 'UNLOCK' : 'LOCK';


    const confirmAction = window.confirm(`Are you sure, you want to ${action} ${employeeData[index].name}?`);

    if (confirmAction) {
      try {
        const response = await fetch(`${BACKEND_URL}/users/toogle_account_lock?email=${email}`, { headers: headers });
        const data = await response.json();
        // console.log(data);
        if (data.success) {
          // Update the state to reflect the new status
          const updatedEmployeeData = [...employeeData];
          updatedEmployeeData[index].isLOCKED = !employeeData[index].isLOCKED;
          setEmployeeData(updatedEmployeeData);

          // Update the state or perform any other action as needed
          // console.log(`Account ${action}ed successfully for ${employeeData[index].name}`);
          toast.success(`Account ${action}ed successfully for ${employeeData[index].name}`, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          });
        } else {
          // console.error(`Failed to ${action} account for ${employeeData[index].name}`);
          toast.error(`Failed to ${action} account for ${employeeData[index].name}`, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          })
        }
      } catch (error) {
        // console.error(`Error while ${action}ing account for ${employeeData[index].name}`, error);
        toast.error(`Error while ${action}ing account for ${employeeData[index].name}`, error, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        });

      }
    }
  };
  const handleUpadateData = (value) => {
    // console.log(value)
    setId(value)
    setShowForm(!showForm)
  }
  const handleLeaves = (value) => {

  }

  return (
    <>
      <div className="adminHome">
        <div className="userTable">
          <div className="tableHead">
            <div className="isLocked">Account Status</div>
            <div className="isActive">Emp Status</div>
            <div className="dp">DP</div>
            <div className="empId">Emp ID</div>
            <div className="empName">Name</div>
            <div className="empEmail">Email</div>
            <div className="empPhone">Phone</div>
            <div className="empDesg">Designation</div>
            {/* <div className="empProjMng">Project Manager</div> */}
            <div className="empProj">Project</div>
            <div className="empOptn">More</div>
          </div>

          <div className="tableBody">
            {employeeData.map((employee, index) => (
              <div key={employee._id} className="tableBodyRow">
                <div className="isLocked" onClick={() => handleLockAction(index)}>
                  {employee.isLOCKED ? (
                    <>
                      <img width="30" height="30" src={lock} alt="locked" />
                      <div className="popup popup1">Unlock {employee.name ? employee.name : employee.email}</div>
                    </>

                  ) : (
                    <>
                      <img width="30" height="30" src={unLock} alt="unlocked" />
                      <div className="popup popup1">Lock {employee.name ? employee.name : employee.email}</div>
                    </>
                  )}
                </div>
                <div className="isActive">
                  {employee.isACTIVE ? (
                    <span className="active" style={{ color: "green" }}></span>
                  ) : (
                    <span className="inactive" style={{ color: "red" }}></span>
                  )}
                </div>
                <div className="dp">{employee.dp ? (
                  firstImageLoaded ? (
                    <>
                      <div className="dpImg" onClick={() => console.log(`http://localhost:5008${employee?.dp}`)}>
                        <img
                          width="25" height="25" style={{ borderRadius: '50%' }}
                          src={`http://localhost:5008${employee?.dp}`}
                          alt="empDp"
                        // onError={handleFirstImageError}

                        />
                        <div className="dpHoverImg">
                          <img
                            style={{ borderRadius: '50%' }}
                            src={`http://localhost:5008${employee?.dp}`}
                            alt="EmpDP"
                          />
                        </div>

                      </div>
                    </>
                  ) : (
                    <img
                      width="25" height="25"
                      src={circleUser}
                      alt="circleUser"
                    />
                  )
                ) : (<img width="25" height="25" src={circleUser} alt="DP" />)}</div>
                <div className="empId"><div className="fitContent" >{employee.empId}</div></div>
                <div className="empName"><div className="fitContent" >{employee.name}</div></div>
                <div className="empEmail">
                  <div className="fitContent" style={{ width: 'calc(100% - 20px)' }}>
                    {employee.email}
                  </div>
                  {employee.email && (
                    <a
                      href={`mailto:${employee.email}`}
                      target="_blank"
                      className="mailbox"
                      aria-label={`Send mail to ${employee.name || employee.email}`}
                    >
                      <img
                        width="20"
                        height="20"
                        src={mailV1}
                        alt={`Send mail to ${employee.name || employee.email}`}
                      />
                      <div className="popup popup2">
                        Mail {employee.name || employee.email}
                      </div>
                    </a>
                  )}
                </div>

                <div className="empPhone">
                  {employee.phone ? (
                    <>
                      <div className="fitContent" style={{ width: 'calc(100% - 20px)' }} >{employee.phone}</div>

                      <a href={`https://wa.me/${employee.phone}`} target="_blank" className="mailbox" >
                        <img width="18" height="18" src={whatsapp_v4} alt="whatsapp--v4" />
                        <div className="popup popup2">Whatsapp {employee.name ? employee.name : employee.email}</div>
                      </a>

                    </>
                  ) : ""}

                </div>
                <div className="empDesg"><div className="fitContent" >{employee.designation}</div></div>
                {/* <div className="empProjMng"><div className="fitContent" >{employee.projectDetails ? (employee.projectDetails.projectManager ? employee.projectDetails.projectManager.email : "") : ""}</div></div> */}
                <div className="empProj">
                  {employee?.projects
                    ?.filter((project) => project?.status !== "dropped") // Filter out "dropped" projects
                    .map((project, index) => (
                      <div className="fitContent" key={index}>
                        {`${index + 1}. ${project?.projectId?.name || "No Project Name"}`}
                      </div>
                    ))}
                </div>

                <div className={`empOptn dropdown ${showDiv[index] ? "more-btn-active" : ""}`}>

                  {showDiv[index] ? (
                    <>
                      <img
                        width="20"
                        height="20"
                        src={cancel_v1}
                        alt="cancel--v1"
                        className="dropbtn"
                        onClick={() => handleDropdownClick(index)}
                      />
                      <div className="dropdown-content">

                        <a onClick={() => {
                          localStorage.setItem("adminSetUser", employee._id);
                          navigate("/timesheet");
                        }}>Timesheet</a>
                        <a
                          onClick={() => handleUpadateData(employee._id)}
                        >Update Details</a>
                        <a onClick={() => handleShowModal(index)}>Remove</a>
                        <a onClick={() => {
                          localStorage.setItem("adminSetUser", employee._id);
                          navigate("/admin/leave_appliacation");
                        }}>Leave List</a>
                      </div>
                    </>

                  ) : (
                    <>
                      <img
                        width="20"
                        height="20"
                        src={forward_v1}
                        alt="forward--v1"
                        className="dropbtn"
                        onClick={() => handleDropdownClick(index)}
                      />
                    </>
                  )
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
        {
          showForm ? <UpdateDetails setShowForm={setShowForm} id={id} /> : ''
        }

        {/* Remove Emp */}
        {showModal ? (
          <div className="alertBox">

            <div className="removeEmpModal">
              <h2> Remove  {employeeData[selectedEmployeeIndex].name || employeeData[selectedEmployeeIndex].email} ⚠️⚠️⚠️</h2>
              <label>
                <input
                  type="checkbox"
                  value={isPermanentDelete}
                  onChange={() => setIsPermanentDelete(!isPermanentDelete)}
                />
                &nbsp;
                Select to <span style={{ fontWeight: "bold" }}>Delete Permanently</span>
                <img width="15" height="15" src={dangerAlert} alt="dangerAlert"></img>
              </label>
              <hr />
              <tt>To confirm, type "<span className="confirmText">{confirmText}</span>" in the box below</tt>
              <input
                type="text"
                placeholder="Type Here"
                className="confirmInput"
                value={enteredText}
                onChange={handleInputChange}
              />
              <div className="modalFooter">
                <button
                  className={enteredText === confirmText ? "btnActive" : "btnInactive"}
                  onClick={() => enteredText === confirmText && handleRemoveAction()}
                >
                  Delete
                </button>

                <button onClick={() => handleCloseModal()}>Cancel</button>
              </div>
            </div>
          </div>
        ) : ""}

      </div>
      <Toaster />

    </>
  );
};

export default AdminHome;
