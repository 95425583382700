import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import tick from "../../assets/tick.png";
import cross from "../../assets/cross_red.png";
import dustbin from "../../assets/dustbin.png";
import left_arrow from "../../assets/timesheet/leftArrow.png";
import { useNavigate } from "react-router-dom";

const LeaveApplication = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const navigate = useNavigate();
  const { handelSignOut } = useContext(AuthContext);
  const [option, setOption] = useState("all");
  const [datas, setDatas] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const userId = localStorage.getItem("adminSetUser");
  const [searchQuery, setSearchQuery] = useState("");
  const [originalDatas, setOriginalDatas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${BACKEND_URL}/users/leave/view_leaves?status=${option}`, {
          headers: headers,
        })
        .then((res) => {
          const sortedData = _.orderBy(
            res.data.data,
            [(item) => new Date(item.createdAt)],
            ["desc"]
          );
          setDatas(sortedData);
        })
        .catch((err) => {
          // console.log("err", err);
          alert("something went wrong");
          handelSignOut();
        });
    };
    const fetchUserData = async () => {
      await axios
        .get(
          `${BACKEND_URL}/users/leave/view_particular_leaves?status=${option}&userId=${userId}`,
          { headers: headers }
        )
        .then((res) => {
          const sortedData = _.orderBy(
            res.data.data,
            [(item) => new Date(item.createdAt)],
            ["desc"]
          );
          setDatas(sortedData);
        })
        .catch((err) => {
          // console.log("err", err);
          alert("something went wrong");
          handelSignOut();
        });
    };
    if (userId) {
      // console.log("user id");
      fetchUserData();
    } else {
      // console.log("admin");
      fetchData();
    }
  }, [option, refresh]);
  // useEffect(() => {
  //   if (datas) console.log("leave list", datas);
  // }, [datas]);
  const formatDate = (inputDate) => {
    // Check if inputDate is a valid date
    if (!inputDate || isNaN(new Date(inputDate))) {
      return "Invalid Date";
    }

    const date = new Date(inputDate);
    const option = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", option).format(date);
  };
  const handleApprove = async (value) => {
    // console.log("approved", datas[value]);

    await axios
      .patch(
        `${BACKEND_URL}/users/leave/approve_leave?leaveId=${datas[value]._id}`,
        {},
        { headers: headers }
      )
      .then((res) => {
        // console.log("approved leave", res);
        alert(res.data.message);
        setRefresh(refresh + 1);
      });

    // console.log("error showing",err)
  };
  const handleReject = async (value) => {
    // console.log("rejected", datas[value]);
    try {
      const res = await axios.patch(
        `${BACKEND_URL}/users/leave/reject_leave?leaveId=${datas[value]._id}`,
        {}, // Pass an empty object if no payload is required
        { headers: headers } // Move headers outside the payload
      );
      // console.log("reject leave", res.data.message);
      alert(res.data.message);
      setRefresh(refresh + 1);
    } catch (err) {
      // console.log(err);
      alert("Half day cannot be rejected or another issue occurred");
    }
  };
  // const handleDelete = (value) => {
  //   console.log("rejected", datas[value]);
  // };

  useEffect(() => {
    // Initialize originalDatas when datas is fetched
    setOriginalDatas(datas);
  }, [datas]);

  const filterData = (data) => {
    const searchFields = [
      "empId",
      "name",
      "type",
      "startDate",
      "endDate",
      "noOfDays",
      "status",
    ];
    return searchFields.some((field) =>
      String(data.user?.[field] || data[field])
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  };
  const filteredDatas = datas.filter(filterData);

  return (
    <div className="leaveApp">
      {userId ? (
        <div style={{ textAlign: "center" }}>
          <h2>Go Back to click the Arrow</h2>
          <img
            src={left_arrow}
            alt="leftArrow"
            onClick={() => {
              navigate("/admin/home");
              localStorage.removeItem("adminSetUser");
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      ) : (
        ""
      )}
      <h1>Leave Application</h1>
      <label htmlFor="options">
        Sort Leave Applications According to Status:{" "}
      </label>
      <select
        name="options"
        onChange={(e) => {
          setOption(e.target.value);
        }}
        id="statusOptions"
      >
        <option value="all">All</option>
        <option value="pending">Pending</option>
        <option value="approved">Approved</option>
        <option value="rejected">Rejected</option>
      </select>
      <div>
        <label htmlFor="searchBar">Search Employee Name or ID: </label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
        />
      </div>
      <div className="project_list">
        <table>
          <thead>
            <th>Sl.No</th>
            <th>EmpId</th>
            <th>Emp Name</th>
            <th>Leave Type</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>No Of Days</th>
            <th>Status</th>
            {(option === "all" ||
              option === "pending" ||
              option === "rejected") && <th>Approve</th>}
            {(option === "all" ||
              option === "pending" ||
              option === "approved") && <th>Reject</th>}
          </thead>
          <tbody>
            {datas && datas.length > 0 ? (
              filteredDatas.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.user?.empId}</td>
                  <td>{data.user?.name}</td>
                  <td>{data.type}</td>
                  <td>{formatDate(data.startDate)}</td>
                  <td>{formatDate(data.endDate)}</td>
                  <td>{data.noOfDays}</td>
                  <td>{data.status}</td>
                  {(option === "all" ||
                    option === "pending" ||
                    option === "rejected") && (
                      <td>
                        <img
                          src={tick}
                          alt="tick"
                          width={25}
                          height={25}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleApprove(index)}
                        />
                      </td>
                    )}
                  {(option === "all" ||
                    option === "pending" ||
                    option === "approved") && (
                      <td>
                        <img
                          src={cross}
                          alt="tick"
                          width={25}
                          height={25}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleReject(index)}
                        />
                      </td>
                    )}
                </tr>
              ))
            ) : (
              <h4>No {option} application available to show</h4>
            )}
          </tbody>
        </table>

        <ToastContainer />
      </div>
    </div>
  );
};

export default LeaveApplication;
