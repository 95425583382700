import React, { useContext, useEffect, useState } from "react";
import "../../allCss/usercss/updateUser.css";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { logDOM } from "@testing-library/react";

export const UpdateDetails = ({ setShowForm, id }) => {
    const navigate = useNavigate();
    // const { userId } = useContext(AuthContext);
    // console.log("userId", userId);
    const userId = localStorage.getItem("userId")
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`,
    }
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        image: null, // to store the selected image
        empId: "",
        designation: "",
    });
    const handleImageChange = (e) => {
        // Update the state with the selected image file
        setData({ ...data, image: e.target.files[0] });
    };
    
    useEffect(() => {
        // console.log("id",id);
        try {
            
            axios.get(`${BACKEND_URL}/users/profile?userId=${id}`, { headers: headers })
                .then((res) => {
                    /* The commented line `// console.log("get userid for updateuser:", res)` is not doing
                    anything in the code. It is simply a commented out console.log statement that is not
                    being executed. */
                    // console.log("get userid for updateuser:", res)
                    // console.log(res.data.user)
                    const data = res.data.user;
                    // console.log(data);
                    setData({
                        name: data.name,
                        email: data.email,
                        password: data.password,
                        phone: data.phone,
                        empId: data.empId,
                        designation: data.designation,
    
                    });
                })
        } catch (error) {
            // console.log("error" , error);
        }
           
            
    }, [])

    useEffect(() => {
        // console.log(data)
    }, [data])

    useEffect(() => { handleUndefined() }, [])
    const handleUndefined = () => {
        if (!userId) {
            alert("Session Timeout");
            localStorage.removeItem("token");
            navigate("/");
        }
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        // console.log("data:", data);
        try {
            // console.log("userid after submit", userId)
            handleUndefined();
            axios.post(`${BACKEND_URL}/users/update/${id}`, data, { headers: headers }).then((res) => {
                // console.log("updated res:", res);
                setShowForm(false)
            });
        } catch (error) {
            // console.log(error, "error showing");
        }
    };

    return (
        <>

            <div className="update_user">
                <p onClick={() => setShowForm(false)}>X</p>
                <form onSubmit={handelSubmit}>
                    {data.image && (
                        <div>
                            <p>Selected Image:</p>
                            <img
                                src={URL.createObjectURL(data.image)}
                                alt="Selected"
                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                            />
                        </div>
                    )}

                    <label htmlFor="name">Name :</label>
                    <input
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                    />
                    <label htmlFor="email">Email :</label>
                    <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                    />
                    <label htmlFor="password">Password :</label>
                    <input
                        type="password"
                        name="password"
                        value={data.password}
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                    />
                    <label htmlFor="phone">Phone :</label>
                    <input
                        type="number"
                        name="phone"
                        value={data.phone}
                        onChange={(e) => setData({ ...data, phone: e.target.value })}
                    />
                    <label htmlFor="empId">EmpId :</label>
                    <input
                        type="text"
                        name="empId"
                        value={data.empId}
                        onChange={(e) => setData({ ...data, empId: e.target.value })}
                    />
                    <label htmlFor="designation">Designation :</label>
                    <input
                        type="text"
                        name="designation"
                        value={data.designation}
                        onChange={(e) => setData({ ...data, designation: e.target.value })}
                    />
                    <label htmlFor="image">Upload Image:</label>
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageChange}
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </>
    );
};