import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../page/Login";
import { Home } from "../page/User/Home";
import ApplyLeave from "../page/User/ApplyLeave";
import Timesheet from "../page/User/Timesheet";
import AdminHome from "../page/admin/AdminHome";
import Header from "../component/Header";
import AdminHeader from "../component/AdminHeader";
import Footer from "../component/Footer";
import ProtectedRoutes from "./ProtectedRoutes";
import HolidayList from "../page/HolidayList";
import AuthContext from "../context/AuthContext";
import UpdateUser from "../page/User/UpdateUser";
import AddHolidayForm from "../page/admin/AddHolidayForm";
import CEOContent from "../page/User/CEOContent";
import { RegisterUser } from "../page/admin/RegisterUser";
import { AddProject } from "../page/admin/AddProject";
import ProjectList from "../page/admin/ProjectList";
import { AdminCheckRoute } from "./AdminCheckRoute";
import { EmpCheckRoute } from "./EmpCheckRoute";
import Holiday from "../page/User/Holiday";
import LeaveApplication from "../page/admin/LeaveApplication";
import { DeletedEmployee } from "../page/admin/DeletedEmployee";

export const AllRoutes = () => {
  // const { role } = useContext(AuthContext);
  const role = localStorage.getItem("role");
  // console.log("role", role);
  return (
    <>
      <Routes>
        <Route path="/" Component={Login}></Route>

        <Route
          path="/holiday-list"
          element={
            <>
              <AdminCheckRoute element={<AdminHeader />} />
              <EmpCheckRoute element={<Header />} />
              <ProtectedRoutes element={<HolidayList />} />
              <EmpCheckRoute element={<Footer />} />
            </>
          }
        ></Route>

        {/* Users route */}
        <Route
          path="/home"
          element={
            <>
              <Header />
              <ProtectedRoutes element={<Home />} />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/CEOContent"
          element={
            <>
              <Header />
              <ProtectedRoutes element={<CEOContent />} />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/timesheet"
          element={
            <>
              <AdminCheckRoute element={<AdminHeader />} />
              <EmpCheckRoute element={<Header />} />
              <ProtectedRoutes element={<Timesheet />} />
              <EmpCheckRoute element={<Footer />} />
            </>
          }
        ></Route>

        <Route
          path="/apply_leave"
          element={
            <>
              <Header />
              <ProtectedRoutes element={<ApplyLeave />} />
            </>
          }
        ></Route>
        <Route
          path="/update_user"
          element={
            <>
              <Header />
              <ProtectedRoutes element={<UpdateUser />} />
              <Footer />
            </>
          }
        ></Route>

        {/* Admins route */}
        <Route
          path="/admin/home"
          element={
            <>
              <AdminHeader />

              <ProtectedRoutes element={<AdminHome />} />
            </>
          }
        ></Route>

        <Route
          path="/admin/project_list"
          element={
            <>
              <AdminHeader />

              <ProtectedRoutes element={<ProjectList />} />
            </>
          }
        ></Route>

        <Route
          path="/admin/add_holiday"
          element={
            <>
              <AdminHeader />

              <ProtectedRoutes element={<AddHolidayForm />} />
            </>
          }
        ></Route>

        <Route
          path="/admin/register"
          element={
            <>
              <AdminHeader />

              <ProtectedRoutes element={<RegisterUser />} />
            </>
          }
        ></Route>

        <Route
          path="/admin/add_project"
          element={
            <>
              <AdminHeader />

              <ProtectedRoutes element={<AddProject />} />
            </>
          }
        ></Route>


        <Route
          path="/admin/leave_appliacation"
          element={
            <>
              <AdminHeader />

              <ProtectedRoutes element={<LeaveApplication />} />
            </>
          }
        ></Route>
        
        <Route
          path="/admin/deleted_emps"
          element={
            <>
              <AdminHeader />

              <ProtectedRoutes element={<DeletedEmployee />} />
            </>
          }
        ></Route>


        {/* <Route path="/admin_home" Component={Home}></Route> */}
      </Routes>
    </>
  );
};
